<template>
    <section>
        <b-row>
            <div class="col-md-8 col-lg-9">
                <div v-if="isLoaded">
                    <b-card v-if="users.length > 0">
                        <div
                            class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                            "
                        >
                            <h4>Users List</h4>
                        </div>
                        <div class="my">
                            <!-- alert -->
                            <b-alert
                                v-height-fade.appear
                                :show="this.alert.visible"
                                dismissible
                                class="mb-0"
                                :variant="this.alert.type"
                            >
                                <div class="alert-body">
                                    {{ this.alert.text }}
                                </div>
                            </b-alert>
                            <!-- alert -->
                        </div>
                        <!-- users list table -->
                        <b-table
                            responsive
                            class="mt-1"
                            :striped="striped"
                            :bordered="bordered"
                            :outlined="outlined"
                            :fields="fields"
                            :items="users"
                        >
                            <template #cell(created_at)="data">
                                {{ data.value }}
                            </template>
                            <template #cell(name)="data">
                                <div class="d-flex flex-row align-items-center">
                                    <span
                                        class="status-circle mr-2"
                                        :class="
                                            data.item.status === 'active'
                                                ? 'bg-success'
                                                : 'bg-danger'
                                        "
                                    ></span>
                                    <div class="d-flex flex-column">
                                        <span class="first_name">
                                            {{ data.item.first_name }}
                                        </span>
                                        <span class="last_name">
                                            {{ data.item.last_name }}
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <template #cell(username)="data">
                                {{ data.value }}
                            </template>
                            <template #cell(email)="data">
                                {{ data.value }}
                            </template>
                            
                            <template #cell(phone)="data">
                                {{ data.item.profile ? data.item.profile.phone_number : '--' }}
                            </template>
                            <template #cell(linkedin)="data">
                                {{ data.item.profile ? data.item.profile.linkedin : '--' }}
                            </template>
                            <template #cell(birth_date)="data">
                                {{
                                    data.item.profile != null &&
                                    data.item.profile.birth_date != null
                                        ? data.item.profile.birth_date
                                        : "Not Defined"
                                }}
                            </template>
                            <template #cell(action)="data">
                                <b-button
                                    variant="gradient-info"
                                    class="btn-icon"
                                    v-on:click="userAction(data.item.id)"
                                >
                                    <feather-icon icon="Edit2Icon" />
                                </b-button>
                            </template>
                        </b-table>
                        <!-- users list table -->

                        <b-pagination
                            class="mb-0"
                            v-model="page"
                            :per-page="perPage"
                            :total-rows="count"
                            @change="pageChangeHandler"
                        ></b-pagination>
                    </b-card>
                    <b-card v-else class="text-center">
                        <h4 class="text-secondary mb-0">Users Not Found</h4>
                    </b-card>
                </div>
                <div v-else class="text-center my-3">
                    <b-spinner label="Loading..." />
                    <span class="d-block mt-1"> Loading Users... </span>
                </div>
            </div>
            <div class="col-md-4 col-lg-3">
                <!-- users filter -->
                <b-card>
                    <div
                        class="
                            d-flex
                            flex-row
                            justify-content-between
                            align-items-center
                        "
                    >
                        <h4>Filter Users</h4>
                    </div>
                    <b-form
                        class="auth-login-form mt-2"
                        @submit.prevent="searchHandler"
                    >
                        <b-form-group label="Name" label-for="s-name">
                            <b-form-input
                                v-model="name"
                                id="s-name"
                                placeholder="Enter user name"
                            />
                        </b-form-group>
                        <b-form-group label="Username" label-for="s-username">
                            <b-form-input
                                v-model="username"
                                id="s-username"
                                placeholder="Enter user username"
                            />
                        </b-form-group>
                        <b-form-group label="Email" label-for="s-email">
                            <b-form-input
                                v-model="email"
                                id="s-email"
                                placeholder="Enter user email"
                            />
                        </b-form-group>
                        <b-button type="submit" variant="primary" block
                            >Filter</b-button
                        >
                    </b-form>
                </b-card>
                <!-- users filter -->
            </div>
        </b-row>
    </section>
</template>
<script>
import {
    BRow,
    BCard,
    BCardText,
    BImg,
    BButton,
    BSpinner,
    BBadge,
    BTable,
    BFormGroup,
    BFormInput,
    BForm,
    BAlert,
    BPagination,
} from "bootstrap-vue";
import { heightFade } from "@core/directives/animations";
import axios from "axios";
import _ from "lodash";
export default {
    directives: {
        "height-fade": heightFade,
    },
    components: {
        BRow,
        BCard,
        BCardText,
        BSpinner,
        BImg,
        BButton,
        BBadge,
        BTable,
        BFormGroup,
        BFormInput,
        BForm,
        BAlert,
        BPagination,
    },
    data() {
        return {
            // table
            page: 1,
            count: 1,
            perPage: 1,
            startNum: 0,
            striped: true,
            bordered: true,
            outlined: true,
            fields: [
                { key: "created_at", label: "#" },
                { key: "name", label: "Name" },
                { key: "username", label: "Username" },
                { key: "email", label: "Email" },
                { key: "phone", label: "Phone" },
                { key: "linkedin", label: "Linkedin" },
                { key: "birth_date", label: "Birth Date" },
                { key: "action", label: "Actions" },
            ],
            users: [],
            // filter
            name: undefined,
            username: undefined,
            email: undefined,
            // is users loaded flag
            isLoaded: false,
        };
    },
    computed: {
        alert: {
            get() {
                return this.$store.getters["posts/getAlert"];
            },
            set(value) {
                this.$store.commit("posts/setAlert", value);
            },
        },
    },
    methods: {
        getUsers(_params) {
            axios
                .get("https://api.soclib.net/api/users", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                    params: {
                        ..._params,
                        per_page: 50,
                        page: this.page,
                    },
                })
                .then((response) => {
                    const { total } = response.data;
                    const { per_page } = response.data;

                    this.startNum = (this.page - 1) * this.perPage;

                    _.map(response.data.data, (user) => {
                        this.startNum++;
                        user.created_at = this.startNum;
                    });

                    this.users = _.values(response.data.data);
                    this.perPage = per_page;
                    this.count = total;
                    this.isLoaded = true;
                })
                .catch((error) => {});
        },
        userAction(id) {
            this.$router.replace({
                name: "users-edit",
                params: {
                    id,
                },
            });
        },
        pageChangeHandler(value) {
            this.page = value;
            const params = {
                name: this.name,
                username: this.username,
                email: this.email,
            };
            this.getUsers(params);
        },
        resetPagination() {
            this.page = 1;
        },
        searchHandler() {
            this.isLoaded = false;
            const params = {
                name: this.name,
                username: this.username,
                email: this.email,
            };
            this.resetPagination();
            this.getUsers(params);
        },
    },
    created() {
        // hide alert after 5 seconds
        if (this.alert.visible) {
            setTimeout(() => {
                this.$store.commit("posts/hideAlert");
            }, 5000);
        }
        // take page from url
        if (this.$route.query.hasOwnProperty("page")) {
            this.page = this.$route.query.page;
        }
        // get all users from api
        this.getUsers();
    },
};
</script>
<style lang="scss">
.status-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}
</style>
